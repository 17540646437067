.wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 200px 0;
  background: #ffffff;

  .content {
    max-width: 1320px;
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 64px;

  .title-subtitle {
    .title {
      color: #232323;
      font-family: "SF Pro Display Medium";
      font-size: 48px;
      font-style: normal;
      line-height: 57px; /* 118.75% */
      letter-spacing: 0.35px;
    }

    .subtitle {
      color: #7b7b7b;

      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */

      span {
        color: #232323;

        /* subhead */
        font-family: "SF Pro Display Medium";
        font-size: 22px;
        font-style: normal;
        line-height: 28px; /* 127.273% */
        letter-spacing: -0.23px;
      }
    }
  }

  .button-slider-wrapper {
    display: flex;
    align-items: center;

    button {
      border: none;
      display: flex;
      width: 46px;
      height: 46px;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border-radius: 2px;
      border: 0.5px solid #232323;
      background: #fff;
      cursor: pointer;

      .arrow {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }

      &:first-child {
        margin-right: 16px;
      }

      &:disabled {
        border: 0.5px solid #a7a7a7;
        cursor: not-allowed;
      }
    }
  }
}

.current-listing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .info {
    width: calc(100% - 360px - 40px);
    .location {
      display: flex;
      align-items: center;
      svg {
        width: 18px;
        height: 18px;
      }

      overflow: hidden;
      color: #7b7b7b;
      text-overflow: ellipsis;

      /* body */
      font-family: "SF Pro Display Regular";
      font-size: 20px;
      font-style: normal;
      line-height: 25px; /* 125% */
      letter-spacing: -0.45px;
      margin-bottom: 12px;
    }

    .title {
      color: #232323;

      /* title-1 */
      font-family: "SF Pro Display SemiBold";
      font-size: 38px;
      font-style: normal;
      line-height: 46px; /* 121.053% */
      letter-spacing: 0.37px;
      margin-bottom: 16px;
    }

    .details {
      display: flex;
      align-items: center;
      margin-bottom: 48px;
      justify-content: space-between;
      span {
        color: #a7a7a7;

        /* body */
        font-family: "SF Pro Display Regular";
        font-size: 17px;
        font-style: normal;
        line-height: 22px; /* 129.412% */
      }

      .black {
        color: #4f4f4f;

        /* headline */
        font-family: "SF Pro Display SemiBold";
        font-size: 17px;
        font-style: normal;
        line-height: 22px; /* 129.412% */
        letter-spacing: -0.43px;
      }

      svg {
        width: 8px;
        height: 8px;
      }
    }

    .nilai-transaksi {
      color: #a7a7a7;

      /* body */
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */
    }
    .price {
      color: #232323;

      /* title-1 */
      font-family: "SF Pro Display SemiBold";
      font-size: 28px;
      font-style: normal;
      line-height: 34px; /* 121.429% */
      letter-spacing: -0.33px;

      span {
        color: #4f4f4f;

        /* title-3 */
        font-family: "SF Pro Display Regular";
        font-size: 20px;
        font-style: normal;
        line-height: 25px; /* 125% */
        letter-spacing: -0.45px;
      }
    }
  }

  .wrapper-image {
    width: 360px;
    height: 360px;
    border-radius: 2px;
    border: 1px solid #f5f5f5;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 2px;
    }
  }
}

@media (max-width: 1500px) {
  .wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 200px 0;
    .content {
      max-width: 960px;
      width: 100%;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 64px;

    .title-subtitle {
      .title {
        color: #232323;
        font-family: "SF Pro Display Medium";
        font-size: 48px;
        font-style: normal;
        line-height: 57px; /* 118.75% */
        letter-spacing: 0.35px;
      }

      .subtitle {
        color: #7b7b7b;

        /* footnote */
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;

        span {
          color: #232323;

          /* subhead */
          font-family: "SF Pro Display Medium";
          font-size: 15px;
          font-style: normal;
          line-height: 20px; /* 133.333% */
          letter-spacing: -0.23px;
        }
      }
    }

    .button-slider-wrapper {
      display: flex;
      align-items: center;

      button {
        display: flex;
        width: 46px;
        height: 46px;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-radius: 2px;
        background: #fff;
        cursor: pointer;
        .arrow {
          width: 24px;
          height: 24px;
        }

        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }

  .current-listing {
    display: flex;
    align-items: center;

    .info {
      width: calc(100% - 360px);
      .location {
        display: flex;
        align-items: center;
        svg {
          width: 18px;
          height: 18px;
        }

        overflow: hidden;
        color: #7b7b7b;
        text-overflow: ellipsis;

        /* body */
        font-family: "SF Pro Display Regular";
        font-size: 17px;
        font-style: normal;
        line-height: 22px; /* 129.412% */
        margin-bottom: 12px;
      }

      .title {
        color: #232323;

        /* title-1 */
        font-family: "SF Pro Display SemiBold";
        font-size: 28px;
        font-style: normal;
        line-height: 34px; /* 121.429% */
        letter-spacing: -0.33px;
        margin-bottom: 16px;
      }

      .details {
        display: flex;
        align-items: center;
        margin-bottom: 48px;

        span {
          color: #a7a7a7;

          /* body */
          font-family: "SF Pro Display Regular";
          font-size: 17px;
          font-style: normal;
          line-height: 22px; /* 129.412% */
        }

        .black {
          color: #4f4f4f;

          /* headline */
          font-family: "SF Pro Display SemiBold";
          font-size: 17px;
          font-style: normal;
          line-height: 22px; /* 129.412% */
          letter-spacing: -0.43px;
        }

        svg {
          width: 8px;
          height: 8px;
        }
      }

      .nilai-transaksi {
        color: #a7a7a7;

        /* body */
        font-family: "SF Pro Display Regular";
        font-size: 17px;
        font-style: normal;
        line-height: 22px; /* 129.412% */
      }
      .price {
        color: #232323;

        /* title-1 */
        font-family: "SF Pro Display SemiBold";
        font-size: 28px;
        font-style: normal;
        line-height: 34px; /* 121.429% */
        letter-spacing: -0.33px;

        span {
          color: #4f4f4f;

          /* title-3 */
          font-family: "SF Pro Display Regular";
          font-size: 20px;
          font-style: normal;
          line-height: 25px; /* 125% */
          letter-spacing: -0.45px;
        }
      }
    }

    .wrapper-image {
      width: 360px;
      height: 360px;
      border-radius: 2px;
      border: 1px solid #f5f5f5;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .wrapper {
    padding: 80px 0;

    .content {
      max-width: 378px;
      position: relative;
      z-index: 2;
    }
  }

  .header {
    margin-bottom: 40px;
    .title-subtitle {
      .title {
        color: #232323;
        font-family: "SF Pro Display Medium";
        font-size: 34px;
        font-style: normal;
        line-height: 41px; /* 120.588% */
        letter-spacing: -0.45px;
      }

      .subtitle {
        color: #7b7b7b;

        /* footnote */
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;

        span {
          display: block;
        }
      }
    }

    .button-slider-wrapper {
      position: absolute;
      bottom: -80px;
      right: 51px;
      button {
        width: 46px;
        height: 46px;
        padding: 16px 24px;
        .arrow {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .current-listing {
    flex-direction: column-reverse;
    .info {
      width: 100%;
      .location {
        svg {
          width: 16px;
          height: 16px;
        }

        overflow: hidden;
        color: #7b7b7b;
        text-overflow: ellipsis;

        /* footnote */
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;
        margin-bottom: 6px;
      }

      .title {
        color: #232323;
        font-family: "SF Pro Display SemiBold";
        font-size: 16px;
        font-style: normal;
        line-height: 21px; /* 131.25% */
        letter-spacing: -0.31px;
        margin-bottom: 16px;
      }

      .details {
        span {
          color: #a7a7a7;

          /* footnote */
          font-family: "SF Pro Display Regular";
          font-size: 13px;
          font-style: normal;
          line-height: 18px; /* 138.462% */
          letter-spacing: -0.08px;
        }

        .black {
          color: #4f4f4f;
          font-family: "SF Pro Display SemiBold";
          font-size: 13px;
          font-style: normal;
          line-height: 18px; /* 138.462% */
          letter-spacing: -0.08px;
        }
      }

      .nilai-transaksi {
        color: #a7a7a7;

        /* footnote */
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;
        margin-bottom: 4px;
      }

      .price {
        color: #232323;
        font-family: "SF Pro Display";
        font-size: 16px;
        font-style: normal;
        line-height: 21px; /* 131.25% */
        letter-spacing: -0.31px;
      }
    }
    .wrapper-image {
      margin-bottom: 40px;
      height: 327px;
      width: 378px;
    }
  }
}

@media (max-width: 425px) {
  .wrapper {
    padding: 80px 24px;

    .content {
      max-width: unset;
      width: 100%;
    }
  }

  .current-listing {
    .wrapper-image {
      margin-bottom: 40px;
      height: calc(100vw - 48px);
      width: 100%;
    }
  }
}


@media (max-width: 374px) {
  .wrapper {
    padding: 80px 4vw;
  }
}