.wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 200px 0;
  background: #ffffff;
  .content {
    max-width: 1320px;
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 64px;
  .title {
    color: #232323;
    font-family: "SF Pro Display Medium";
    font-size: 48px;
    font-style: normal;
    line-height: 57px; /* 118.75% */
    letter-spacing: 0.35px;
  }

  .sub-title {
    color: #7b7b7b;
    /* footnote */
    font-family: "SF Pro Display Regular";
    font-size: 17px;
    font-style: normal;
    line-height: 22px; /* 129.412% */
    span {
      color: #232323;
      font-family: "SF Pro Display Bold";
      /* subhead */
      font-size: 22px;
      font-style: normal;
      line-height: 28px; /* 127.273% */
      letter-spacing: -0.23px;
    }
  }
}

.btn {
  border: none;
  border-radius: 2px;
  border: 0.5px solid #232323;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  cursor: pointer;
  height: 46px;
  span {
    color: #232323;

    /* subhead */
    font-family: "SF Pro Display Medium";
    font-size: 15px;
    font-style: normal;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.23px;
  }
}

.wrapper-images {
  display: grid;
  grid-template-columns: repeat(4, 300px);
  grid-column-gap: 40px;
  grid-row-gap: 80px;
  justify-content: center;
  width: 100%;
}

@media (max-width: 1500px) {
  .wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 200px 0;
    .content {
      max-width: 960px;
      width: 100%;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 64px;
    .title {
      color: #232323;
      font-family: "SF Pro Display Medium";
      font-size: 48px;
      font-style: normal;
      line-height: 57px; /* 118.75% */
      letter-spacing: 0.35px;
    }

    .sub-title {
      color: #7b7b7b;
      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;
      span {
        color: #232323;
        /* subhead */
        font-family: "SF Pro Display Medium";
        font-size: 15px;
        font-style: normal;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.23px;
      }
    }
  }

  .btn {
    border: none;
    border-radius: 2px;
    border: 0.5px solid #232323;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    cursor: pointer;
    span {
      color: #232323;

      /* subhead */
      font-family: "SF Pro Display Medium";
      font-size: 15px;
      font-style: normal;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.23px;
    }
  }

  .wrapper-images {
    display: grid;
    grid-template-columns: 293.333px 293.333px 293.333px;
    grid-column-gap: 40px;
    grid-row-gap: 80px;
    justify-content: center;
  }
}

@media (max-width: 1023px) {
  .wrapper {
    padding: 80px 0;
    padding-bottom: 160px;
    .content {
      max-width: 378px;
      position: relative;
    }
  }

  .header {
    margin-bottom: 40px;
    .title {
      color: #232323;
      font-family: "SF Pro Display Medium";
      font-size: 34px;
      font-style: normal;
      line-height: 41px; /* 120.588% */
      letter-spacing: -0.45px;
    }

    .sub-title {
      color: #7b7b7b;

      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;

      span {
        display: block;
      }
    }
  }

  .btn {
    position: absolute;
    bottom: -78px;
    width: 100%;
    padding: 16px 24px;
    span {
      color: #232323;

      /* subhead */
      font-family: "SF Pro Display Medium";
      font-size: 15px;
      font-style: normal;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.23px;
    }
  }

  .wrapper-images {
    grid-template-columns: 100%;
    grid-column-gap: unset;
  }
}

@media (max-width: 425px) {
  .wrapper {
    padding: 80px 24px;

    .content {
      max-width: unset;
      width: 100%;
    }
  }

  .wrapper-images {
    grid-template-columns: 100%;
    grid-column-gap: unset;
  }
}


@media (max-width: 374px) {
  .wrapper {
    padding: 80px 4vw;
  }
}