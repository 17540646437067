.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;

  .content {
    background: #ffffff;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    .wrapper-marketing-name {
      padding: 0 240px;
      padding-top: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 64px;
      .marketing-name {
        font-weight: 700;
        font-size: 34px;
        line-height: 41px;
        color: #232323;
      }
      .btn {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 24px;
        background: #ffffff;
        border: 1px solid #232323;
        box-shadow: 0px 1px 2px rgba(35, 35, 35, 0.06);
        border-radius: 2px;
        cursor: pointer;
        span {
          font-weight: 600;
          font-size: 17px;
          line-height: 22px;
          color: #232323;
        }
      }
    }

    .wrapper-search-and-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 240px;
      padding-bottom: 32px;
      border-bottom: 1px solid #e9e9e9;
      .search {
        display: flex;
        align-items: center;
        padding: 8px 8px 8px 24px;
        border: 1px solid #e9e9e9;
        box-shadow: 0px 1px 2px rgba(35, 35, 35, 0.06);
        border-radius: 2px;
        width: 38%;
        input {
          border: unset;
          width: calc(100% - 40px);
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 22px;
          color: #232323;
          &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            color: #a7a7a7;
          }

          &:focus {
            outline: none;
          }
        }
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
          width: 40px;
          height: 40px;
          background: #2356a6;
          border-radius: 2px;
          cursor: pointer;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .type {
        display: flex;
        align-items: center;
        span {
          background: #ffffff;
          padding: 10px 16px;
          cursor: pointer;
          font-weight: 600;
          font-size: 17px;
          line-height: 22px;
          text-align: center;
          color: #232323;
          border: 1px solid #e9e9e9;

          &:first-child {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }

          &:last-child {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }

          &.active {
            background: #e9eef6;
            color: #2356a6;
          }
        }
      }
    }

    .wrapper-listings {
      padding: 32px 240px;
      padding-bottom: 240px;
      display: grid;
      grid-template-columns: repeat(3, 27.08%);
      justify-content: space-between;
      grid-row-gap: 88px;
      border-bottom: 1px solid #f5f5f5;
      overflow-y: auto;
      height: calc(100vh - 92px - 91px);
    }

    .wrapper-scroll {
      padding: 32px 240px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .floating-button {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    .content {
      .wrapper-marketing-name {
        padding: 32px 132px;
      }
      .wrapper-search-and-type {
        padding: 32px 132px;
      }
      .wrapper-listings {
        padding: 32px 132px;
      }
      .wrapper-scroll {
        padding: 32px 132px;
      }
    }
  }
  @media (max-width: 819px) {
    overflow-y: auto;
    background: #fff;
    .content {
      max-width: 375px;
      position: relative;
      .wrapper-marketing-name {
        padding: 32px 0;
        margin-bottom: 0;
        .marketing-name {
          font-weight: 600;
          font-size: 22px;
          line-height: 28px;
          color: #232323;
        }
      }
      .wrapper-search-and-type {
        padding: 32px 0;
        flex-direction: column;
        align-items: flex-start;
        .search {
          margin-bottom: 24px;
          width: 100%;
        }
      }
      .wrapper-listings {
        padding: 32px 0;
        grid-template-columns: 100%;
        height: auto;
      }
      .wrapper-scroll {
        padding: 32px 0;
      }

      .floating-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 24px;
        right: 24px;
        padding: 12px;
        border-radius: 800px;
        box-shadow: 0px 1px 2px rgba(35, 35, 35, 0.06);
        border: 1px solid #232323;
        cursor: pointer;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .content {
      max-width: 375px;
      .wrapper-marketing-name {
        padding: 32px;
        margin-bottom: 0;
        .marketing-name {
          font-weight: 600;
          font-size: 22px;
          line-height: 28px;
          color: #232323;
        }
      }
      .wrapper-search-and-type {
        padding: 32px;
        flex-direction: column;
        align-items: flex-start;
        .search {
          margin-bottom: 24px;
          width: 100%;
        }
      }
      .wrapper-listings {
        padding: 32px;
        grid-template-columns: 100%;
      }
    }
  }
}

.placeholder {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
  column-gap: 32px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 16px;
    column-gap: 16px;
  }
  .placeholder-item {
    width: 100%;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: row;
    }
    .image {
      width: 100% !important;
      height: 256px !important;
      object-fit: cover;
      margin-bottom: 16px;
      @media (max-width: 768px) {
        height: 96px !important;
        width: 136px !important;
        margin: 0;
        margin-right: 12px;
      }
    }
  }
}
