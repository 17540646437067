.wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fcfcfc;
  position: relative;

  .content {
    background: transparent;
    z-index: 2;
  }
}
