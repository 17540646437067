.wrapper {
  padding-top: 170px;
  padding-bottom: 176px;
  .content {
    display: flex;
    align-items: center;
    margin-bottom: 45px;
    .image {
      margin-right: 96px;
      display: flex;
      align-items: center;

      img {
        box-sizing: border-box;
        width: 307px;
        height: 362px;
        border: 1px solid #e9e9e9;
        border-radius: 200px;
        object-fit: cover;
      }
    }
    .info {
      .name {
        font-weight: 400;
        font-size: 52px;
        line-height: 41px;
        color: #232323;
        margin-bottom: 16px;
        font-family: "Noto Serif Regular";
      }
      .text-welcome {
        font-weight: 300;
        font-size: 36px;
        line-height: 43px;
        color: #4f4f4f;
        margin-bottom: 16px;
      }
      .career-level {
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        color: #2356a6;
        margin-bottom: 10px;
      }
      .office {
        font-weight: 300;
        font-size: 17px;
        line-height: 22px;
        color: #4f4f4f;
        margin-bottom: 48px;
      }

      .contacts {
        display: flex;
        align-items: center;
        padding-top: 16px;
        border-top: 1px solid #f5f5f5;

        .lihat-properti {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 24px;
          background: #2356a6;
          border-radius: 2px;
          margin-right: 24px;
          cursor: pointer;
          border: 1px solid #2356a6;

          span {
            font-weight: 600;
            font-size: 17px;
            line-height: 22px;
            color: #ffffff;
            white-space: nowrap;
          }
          svg {
            width: 24px;
            height: 24px;
            margin-left: 8px;
          }
        }

        .contact {
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: center;
          align-items: center;
          padding: 12px 24px;
          background: #ffffff;
          border: 1px solid #232323;
          border-radius: 2px;

          .icon {
            width: 24px;
            height: 24px;
            margin-left: 8px;
          }
          .wa {
            font-weight: 600;
            font-size: 17px;
            line-height: 22px;
            color: #232323;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
@media (max-width: 1439px) {
  .wrapper {
    .content {
      .image {
        img {
          width: 238px;
          height: 280.64px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .wrapper {
    .content {
      display: flex;
      justify-content: space-between;
      .image {
        img {
          width: 199.14px;
          height: 234.82px;
        }
      }
      .info {
        width: calc(100% - 180px - 48px);
        .name {
          font-size: 28px;
          line-height: 34px;
          margin-bottom: 8px;
        }
        .text-welcome {
          font-size: 28px;
          line-height: 34px;
        }
        .career-level {
          font-size: 17px;
          line-height: 22px;
        }
        .office {
          font-size: 17px;
          line-height: 22px;
        }

        .contacts {
          .lihat-properti {
            padding: 10px 24px;
            span {
              font-size: 17px;
              line-height: 22px;
            }
            svg {
              display: none;
            }
          }
          .contact {
            padding: 10px 24px;
            .wa {
              font-size: 17px;
              line-height: 22px;
            }
            .icon {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 872px) {
  .wrapper {
    padding-top: 24px;
    padding-bottom: 200px;
    .content {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
      .image {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-right: 0;
        img {
          width: 248.81px;
          height: 293.39px;
        }
      }
      .info {
        width: 100%;
        .name {
          font-size: 34px;
          line-height: 41px;
          margin-bottom: 8px;
        }
        .text-welcome {
          font-weight: 300;
          font-size: 20px;
          line-height: 25px;
          color: #4f4f4f;
          margin-bottom: 8px;
        }
        .contacts {
          position: absolute;
          bottom: -140px;
          flex-direction: row-reverse;
          border: unset;
          justify-content: center;
          width: 100%;
          .lihat-properti {
            margin-right: 0;
            margin-left: 16px;
            span {
              font-size: 17px;
              line-height: 22px;
            }
          }
          .contact {
            .wa {
              font-size: 17px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 374px) {
  .wrapper {
    .content {
      .info {
        .contacts {
          .lihat-properti {
            span {
              font-size: 12px;
            }
          }
          .contact {
            .wa {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 317px) {
  .wrapper {
    .content {
      .info {
        .contacts {
          width: 100%;
          justify-content: center;
          .lihat-properti {
            padding: 4px;
            span {
              font-size: 12px;
            }
          }
          .contact {
            padding: 4px;
            .wa {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 280px) {
  .wrapper {
    padding-bottom: 100px;
    .content {
      .image {
        margin-right: 0;
        width: 100%;

        img {
          width: calc(100vw - 64px);
          height: calc(100vw + 64px);
        }
      }
      .info {
        .contacts {
          width: 100%;
          justify-content: center;
          bottom: -75px;
          .lihat-properti {
            padding: 10px 16px;
            span {
              font-size: 11px;
            }
          }
          .contact {
            padding: 10px 16px;
            .wa {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
