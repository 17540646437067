.content {
  width: 1080px;
  height: 1920px;
  position: relative;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background-image {
  width: 1080px;
  height: 608px;
  right: 0;
  top: 236px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.background-image-4-3 {
  width: 1080px;
  height: 810px;
  right: 0;
  top: 236px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.background-image-5-4 {
  width: 1080px;
  height: 864px;
  right: 0;
  top: 236px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.informations {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
}

.info-top {
  padding-top: 535px;
  padding-left: 56px;
}

.info-top-4-3 {
  padding-top: 434px;
  padding-left: 56px;
}

.info-top-5-4 {
  padding-top: 407px;
  padding-left: 56px;
}

.century-21 {
  width: 214.259px;
  height: 24px;
  object-fit: contain;
  margin-bottom: 12px;
  margin-left: -1px;
}

.wrapper-powered-by {
  display: flex;
  align-items: flex-end;
}

.powered-by {
  color: #fff;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.4);
  font-family: "Typold Bold";
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 1.6px;
}

.one-21 {
  width: 69.271px;
  height: 16px;
  object-fit: contain;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.4);
  margin-left: 4px;
}

.text-bg-color {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  padding-bottom: 303px;
  padding-left: 56px;
}

.property-type {
  color: #fff;
  font-family: "Typold Bold";
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  margin-bottom: 30px;
  padding: 10px 8px;
  border: 2px solid #fff;
  width: fit-content;
}

.property-location {
  color: #fff;
  font-family: "Typold Bold";
  font-size: 96px;
  line-height: 88px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  margin-bottom: 40px;
  width: calc(1080px - 56px - 56px);
}

.wrapper-price-feature {
  display: flex;
  align-items: center;
}

.wrapper-price {
  display: flex;
  align-items: flex-end;
  padding: 16px;
  background: #beaf87;
  width: fit-content;
}

.price {
  color: #252526;
  font-family: "Typold";
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.4px;
}

.price-bold {
  color: #252526;
  font-family: "Typold Bold";
  font-size: 64px;
  line-height: 34px;
  letter-spacing: 0.16px;
}

.wrapper-feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.feature {
  display: flex;
  align-items: center;

  p {
    color: #fff;
    font-family: "Typold Medium";
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.4px;
  }
}

.dot {
  margin: 0 16px;
  width: 12px;
  height: 12px;
  object-fit: contain;
}

.divider {
  width: 100%;
  height: 1px;
  border-bottom: 0.5px solid #fff;
  opacity: 0.8;
  margin: 8px 0;
}

.preview-image {
  width: calc(100vw - 24px);
  height: calc(100vw - 24px);
  object-fit: contain;
}
