.wrapper {
  display: flex;
  align-items: center;
  background: #232323;
  justify-content: center;
  width: 100vw;
  .content {
    padding: 16px 0;
    max-width: 960px;
    p {
      color: #7b7b7b;

      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;
    }
  }
}

@media (max-width: 1023px) {
  .wrapper{
    .content{
      max-width: unset;
    }
  }
}


@media (max-width: 374px) {
  .wrapper {
    padding: 80px 4vw;
  }
}