.content {
  position: absolute;
  right: 500%;
}

.loader {
  position: absolute;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 24px;

  svg {
    width: 100%;
    height: 199.125px;
    object-fit: contain;
  }
}

.title {
  color: #575757;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 11px;
  font-style: normal;
  line-height: 13px;
  letter-spacing: -0.4px;
  margin-top: 16px;
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 0 24px;
  height: 100vh;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.success-icon {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 16px;
}

.success-title {
  color: #232323;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.5px; /* 114.706% */
  letter-spacing: -0.43px;
  margin-bottom: 16px;
}

.success-subtitle {
  color: #575757;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.23px;
  margin-bottom: 48px;
}

.promo {
  display: flex;
  padding: 18px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: #232323;
  color: #e2dcca;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: -0.43px;
  text-transform: capitalize;
  width: calc(100% - 24px);
  max-width: 279px;
  margin-bottom: 32px;
}
