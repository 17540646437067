.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  z-index: 6;
  border-radius: 6px;
  background: #fff;
  padding: 24px 24px 32px 24px;
  width: calc(100vw - 24px);
  max-width: 327px;
  position: relative;
}

.icon-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.icon {
  cursor: pointer;
}

.header {
  font-family: "SF Pro Display SemiBold";
  font-size: 22px;
  margin-bottom: 8px;
}

.sub-header {
  color: #4f4f4f;
  font-size: 17px;
  margin-bottom: 24px;
}

.input-title {
  font-size: 13px;
  margin-bottom: 8px;
}

.input {
  padding: 10px 16px;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05) inset;
  font-size: 17px;
  width: 100%;
  &::placeholder {
    color: #9e9e9e;
    font-size: 17px;
  }
}

.info {
  display: flex;
  align-items: flex-start;
}

.info-icon {
  width: 56px;
  height: 56px;
}

.info-txt {
  color: #7b7b7b;
  font-size: 12px;
}

.divider {
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  height: 1px;
}

.btn {
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
}

.btn-cancel {
  border: 1px solid #232323;
  background: #fff;
  color: #232323;
  font-family: "SF Pro Display SemiBold";
  font-size: 17px;
}

.btn-submit {
  border: 1px solid #2356A6;
  background: #2356A6;
  color: #fff;
  font-family: "SF Pro Display SemiBold";
  font-size: 17px;

  &:disabled{
    border: 1px solid #EEEEEE;
  }

  &:enabled:active{
    background: #fff;
    color: #2356A6;
    border: 1px solid #fff;
  }
}

.spinner-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
