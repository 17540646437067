.wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 200px 0;
  padding-bottom: 96px;
  background: #ffffff;

  .content {
    max-width: 1320px;
    width: 100%;
  }
}

.header {
  padding-bottom: 24px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 64px;
  .title {
    color: #232323;
    font-family: "SF Pro Display Medium";
    font-size: 48px;
    font-style: normal;
    line-height: 57px; /* 118.75% */
    letter-spacing: 0.35px;
    margin-bottom: 8px;
  }
  .sub-title {
    color: #7b7b7b;

    /* footnote */
    font-family: "SF Pro Display Regular";
    font-size: 17px;
    font-style: normal;
    line-height: 22px; /* 129.412% */

    span {
      font-family: "SF Pro Display SemiBold";
      font-size: 22px;
      font-style: normal;
      line-height: 28px; /* 127.273% */
      letter-spacing: -0.23px;
    }
  }
}

.deskripsi {
  color: #232323;
  font-family: "SF Pro Display Regular";
  font-size: 20px;
  font-style: normal;
  line-height: 32.4px; /* 162% */
  letter-spacing: -0.45px;
}

@media (max-width: 1500px) {
  .wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 200px 0;
    padding-bottom: 96px;
    .content {
      max-width: 960px;
      width: 100%;
    }
  }

  .header {
    padding-bottom: 24px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 64px;
    .title {
      color: #232323;
      font-family: "SF Pro Display Medium";
      font-size: 48px;
      font-style: normal;
      line-height: 57px; /* 118.75% */
      letter-spacing: 0.35px;
    }
    .sub-title {
      color: #7b7b7b;

      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;

      span {
        color: #232323;
        font-family: "SF Pro Display SemiBold";
        font-size: 15px;
        font-style: normal;
        line-height: 28px; /* 127.273% */
        letter-spacing: -0.23px;
      }
    }
  }

  .deskripsi {
    color: #232323;
    font-family: "SF Pro Display Regular";
    font-size: 20px;
    font-style: normal;
    line-height: 32.4px; /* 162% */
    letter-spacing: -0.45px;
  }
}

@media (max-width: 1023px) {
  .wrapper {
    padding: 80px 0;
    .content {
      max-width: 378px;
    }
  }

  .header {
    .title {
      color: #232323;
      font-family: "SF Pro Display Medium";
      font-size: 34px;
      font-style: normal;
      line-height: 41px; /* 120.588% */
      letter-spacing: -0.45px;
    }

    .sub-title {
      color: var(--neutral-60, #7b7b7b);

      /* footnote */
      font-family: SF Pro Display;
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;

      span {
        color: #232323;
        /* subhead */
        font-family: "SF Pro Display Medium";
        font-size: 15px;
        font-style: normal;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.23px;
      }
    }
  }

  .deskripsi {
    color: #232323;
    font-family: "SF Pro Display Regular";
    font-size: 17px;
    font-style: normal;
    line-height: 27.506px; /* 161.8% */
  }
}

@media (max-width: 425px) {
  .wrapper {
    padding: 80px 24px;

    .content {
      max-width: unset;
      width: 100%;
    }
  }
}


@media (max-width: 374px) {
  .wrapper {
    padding: 80px 4vw;
  }
}
