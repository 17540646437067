$zIdxLayer1: 10; // background logo
$zIdxLayer2: 20; // background
$zIdxLayer3: 30; // text dll.
$zIdxLayer4: 40; // profile picture
$zIdxLayer5: 50; // profile picture
$zIdxLayer6: 60; // profile picture

.wrapper {
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.preview-image {
  width: 100vw;
  height: 50vh;
  max-width: 1080px;
  max-height: 1080px;
  object-fit: contain;
  margin-bottom: 50px;
  margin-top: 50px;
}

.content-wrapper {
  position: absolute;
  left: -500%;
}

.content {
  position: relative;
  width: 1080px;
  height: 1080px;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow: hidden;
  background: #e6e7e8;
}

.bg-logo {
  width: 739.246px;
  height: 800px;
  flex-shrink: 0;
  object-fit: contain;
  position: absolute;
  z-index: $zIdxLayer1;
  filter: grayscale(50%);
  opacity: 0.05;
  top: 0;
  right: 0;
}

.bg-pattern {
  object-fit: contain;
  width: 1080px;
  height: 1080px;
  position: absolute;
  z-index: $zIdxLayer2;
  filter: grayscale(50%);
  opacity: 0.05;
}

.rank {
  z-index: $zIdxLayer3;
  position: absolute;
  top: -48px;
  left: 48px;
  display: flex;
  align-items: center;
}

.top {
  color: #252526;
  font-family: "Typold Bold";
  font-size: 260px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -52px;
  text-transform: uppercase;
  margin-right: 50px;
}

.rank-number {
  color: #252526;
  font-family: "Typold Bold";
  font-size: 260px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -27px;
  text-transform: uppercase;
}

.box-text-period {
  z-index: $zIdxLayer3;
  position: absolute;
  top: 48px;
  right: 89px;
}

.text-period {
  color: #808285;
  font-family: "Typold";
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  z-index: $zIdxLayer3;
  position: absolute;
  top: 82px;
  right: 70px;
  transform: rotate(-90deg); /* Adjust the degree as per your requirement */
}

.period-img {
  width: 46px;
  position: absolute;
  top: 50px;
  right: 45px;
  z-index: $zIdxLayer3;
}

.career-level {
  color: #252526;
  font-family: "Typold";
  font-size: 28px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  z-index: $zIdxLayer3;
  position: absolute;
  top: 270px;
  left: 48px;
}

.top-rank {
  left: 110px;
}

.scale {
  font-family: "Typold Bold";
}

.box-qr {
  top: 545px;
  left: 48px;
  position: absolute;
  z-index: $zIdxLayer3;
  background: white;
}

.qr {
  width: 156px;
  height: 156px;
}

.scan-qr {
  left: 48px;
  position: absolute;
  z-index: $zIdxLayer3;
  color: #252526;
  font-family: "Typold Bold";
  font-size: 28px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: capitalize;
  top: 725px;
}

.qr-info {
  left: 48px;
  position: absolute;
  z-index: $zIdxLayer3;
  color: #808285;
  font-family: "Typold";
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.16px;
  width: 268px;
  top: 761px;
}

.user-name {
  padding: 0 6px;
  left: 48px;
  position: absolute;
  z-index: $zIdxLayer6;
  background: #beaf87;
  color: #252526;
  font-family: "Typold Bold";
  font-size: 40px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  bottom: 115px;
}

.century-21 {
  width: 178.549px;
  height: 20px;
  object-fit: contain;
  left: 48px;
  position: absolute;
  z-index: $zIdxLayer3;
  bottom: 72px;
}

.company-name {
  left: 48px;
  position: absolute;
  z-index: $zIdxLayer3;
  bottom: 48px;
  color: #3e3934;
  font-family: "Typold Medium";
  font-size: 16px;
  font-style: normal;
  line-height: 89%; /* 14.24px */
  letter-spacing: 0.16px;
  text-transform: capitalize;
}

.c-21 {
  width: 157px;
  height: 200px;
  object-fit: contain;
  right: 48px;
  position: absolute;
  z-index: $zIdxLayer3;
  bottom: 48px;
}

.grain {
  width: 1080px;
  height: 1080px;
  position: absolute;
  z-index: $zIdxLayer4;
  top: 0;
  left: 0;
}

.profile-image {
  position: absolute;
  z-index: $zIdxLayer5;
  right: 121px;
  width: 700px;
  bottom: -34px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
