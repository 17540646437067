.wrapper {
  width: 100%;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #f5f5f5;
  background: #fff;

  .image-wrapper {
    width: 300px;
    height: 260px;
    margin-bottom: 24px;
    img {
      border-radius: 2px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .listing-info {
    padding: 0 24px 24px 24px;
  }

  .location {
    svg {
      width: 14px;
      height: 14px;
    }
    overflow: hidden;
    color: #7b7b7b;
    text-overflow: ellipsis;
    /* footnote */
    font-family: "SF Pro Display Regular";
    font-size: 13px;
    font-style: normal;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.08px;
    margin-bottom: 4px;
  }

  .title {
    overflow: hidden;
    color: #232323;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* body */
    font-family: "SF Pro Display Regular";
    font-size: 17px;
    font-style: normal;
    line-height: 22px; /* 129.412% */
    margin-bottom: 8px;
  }

  .details {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #a7a7a7;

      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;
      white-space: nowrap;
    }
    .black {
      color: #4f4f4f;
      font-family: "SF Pro Display SemiBold";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;
    }
    svg {
      width: 8px;
      height: 8px;

      path {
        fill: #4f4f4f;
      }
    }
  }

  .price-label {
    color: #a7a7a7;

    /* footnote */
    font-family: "SF Pro Display Regular";
    font-size: 13px;
    font-style: normal;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.08px;
  }

  .price {
    color: #232323;
    font-family: "SF Pro Display SemiBold";
    font-size: 17px;
    font-style: normal;
    line-height: 21px; /* 131.25% */
    letter-spacing: -0.31px;
  }
}

@media (max-width: 1500px) {
  .wrapper {
    width: 100%;
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid #f5f5f5;
    background: #fff;

    .image-wrapper {
      width: 293.333px;
      height: 260px;
      margin-bottom: 24px;
      img {
        border-radius: 2px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .listing-info {
      padding: 0 24px 24px 24px;
    }

    .location {
      svg {
        width: 14px;
        height: 14px;
      }
      overflow: hidden;
      color: #7b7b7b;
      text-overflow: ellipsis;
      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;
      margin-bottom: 4px;
    }

    .title {
      overflow: hidden;
      color: #232323;
      text-overflow: ellipsis;
      white-space: nowrap;
      /* body */
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */
      margin-bottom: 8px;
    }

    .details {
      span {
        color: #a7a7a7;

        /* footnote */
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;
      }
      .black {
        color: #4f4f4f;
        font-family: "SF Pro Display SemiBold";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;
      }
      svg {
        width: 8px;
        height: 8px;

        path {
          fill: #4f4f4f;
        }
      }
    }

    .price-label {
      color: #a7a7a7;

      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;
    }

    .price {
      color: #232323;
      font-family: "SF Pro Display SemiBold";
      font-size: 17px;
      font-style: normal;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.31px;
    }
  }
}

@media (max-width: 1023px) {
  .wrapper {
    .image-wrapper {
      width: 378px;
      height: 260px;
    }
    .location {
      overflow: hidden;
      color: #7b7b7b;
      text-overflow: ellipsis;

      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;
    }

    .title {
      overflow: hidden;
      color: #232323;
      text-overflow: ellipsis;

      /* callout */
      font-family: "SF Pro Display Regular";
      font-size: 16px;
      font-style: normal;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.31px;
    }

    .price-label {
      color: #a7a7a7;

      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;
    }

    .price {
      color: #232323;
      font-family: "SF Pro Display SemiBold";
      font-size: 16px;
      font-style: normal;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.31px;
    }

    .details {
      margin-bottom: 16px;
      span {
        color: #a7a7a7;

        /* footnote */
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;
      }

      .black {
        color: #4f4f4f;
        font-family: "SF Pro Display SemiBold";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;
      }
    }
  }
}

@media (max-width: 425px) {
  .wrapper {
    padding: 0;

    .image-wrapper {
      width: 100%;
      height: calc(100vw - 48px);
      margin-bottom: 24px;
      
    }
  }
}
