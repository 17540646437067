.wrapper-dekstop {
  padding: 0px 202px 0 202px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fcfcfc;
  .content {
    max-width: 1320px;
  }

  .description {
    border-radius: 8px;
    border: 1px solid #f5f5f5;
    background: #fff;
    box-shadow: 0px 4px 64px 0px rgba(35, 35, 35, 0.08);
    position: relative;
    max-width: 1124px;
    padding: 40px 98px;
    p {
      color: #232323;
      font-family: "SF Pro Display SemiBold";
      font-size: 20px;
      font-style: normal;
      line-height: 32.36px; /* 161.8% */
      letter-spacing: -0.45px;
      position: relative;
      z-index: 1;
    }
    .wrapper-grey-union {
      position: absolute;
      top: 0;
      left: 0;
      .grey-union {
        width: 95.28px;
        height: 188px;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  .divider {
    height: 1px;
    border-bottom: 1px solid #e9e9e9;
    width: 100vw;
    max-width: 1440px;
    margin-top: 152px;
  }
}

@media (max-width: 1500px) {
  .wrapper-dekstop {
    width: 100vw;
    display: flex;
    justify-content: center;
    .content {
      max-width: 960px;
    }
    .description {
      max-width: 964px;
    }
    .divider {
      height: 1px;
      border-bottom: 1px solid #e9e9e9;
      width: 100vw;
      max-width: 960px;
    }
  }
}

@media (max-width: 1023px) {
  .wrapper {
    padding: 80px 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    .content {
      max-width: 378px;
    }

    .wrapper-kutip {
      margin-bottom: 24px;
      svg {
        width: 18.183px;
        height: 34.092px;
        &:first-child {
          margin-right: 8px;
        }
      }
    }

    .description {
      color: #232323;
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 27.506px; /* 161.8% */
    }
  }
}

@media (max-width: 425px) {
  .wrapper {
    padding: 80px 24px;

    .content {
      max-width: unset;
      width: 100%;
    }
  }
}

@media (max-width: 374px) {
  .wrapper {
    padding: 80px 4vw;
  }
}
