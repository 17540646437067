.wrapper {
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 112px;
}

.profile {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1320px;
  position: relative;
  .left {
    width: 726px;
    padding-bottom: 80px;
    padding-top: 409px;
    .active-marketer-wrapper {
      display: inline-block;
      margin-bottom: 8px;
      .active-marketer {
        display: flex;
        align-items: center;
        font-family: "SF Pro Display SemiBold";
        font-size: 15px;
        padding: 6px;
        border-radius: 2px;
        background: #f2ecdd;
        box-shadow:
          0px 1px 2px 0px rgba(35, 35, 35, 0.08) inset,
          -1px -1px 2px 0px rgba(255, 227, 155, 0.25) inset;

        svg {
          margin-right: 4px;
        }
      }
    }
    .name {
      color: #232323;
      font-family: "SF Pro Display Medium";
      font-size: 76px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.26px;
      margin-bottom: 16px;
    }

    .level-and-company {
      margin-bottom: 24px;
      .level {
        color: #232323;

        /* title-2 */
        font-family: "SF Pro Display SemiBold";
        font-size: 22px;
        font-style: normal;
        line-height: 28px; /* 127.273% */
        letter-spacing: -0.23px;
      }
      svg {
        width: 12px;
        height: 12px;
        margin: 0 16px;
      }
      .company {
        color: #232323;
        font-family: "SF Pro Display Regular";
        font-size: 22px;
        font-style: normal;
        line-height: 28px; /* 127.273% */
        letter-spacing: -0.23px;
      }
    }

    .wrapper-hubungi-saya {
      width: 100%;
      display: flex;
      align-items: center;
      border-top: 1px solid #e9e9e9;
      padding-top: 16px;
      margin-bottom: 96px;

      button {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 40px;
        box-sizing: border-box;
        padding: 12px 24px;
        width: 160px;
        height: 46px;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        border-radius: 2px;
        background: #2356a6;
        cursor: pointer;
        /* subhead */
        font-family: "SF Pro Display Medium";
        color: #fff;
        font-size: 15px;
        font-style: normal;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.23px;
      }

      .info-hub-wa {
        display: flex;
        width: 260px;
        svg {
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          margin-right: 8px;
        }

        p {
          color: #7b7b7b;
          /* caption-1 */
          font-family: "SF Pro Display Regular";
          font-size: 12px;
          font-style: normal;
          line-height: 16px; /* 133.333% */
        }
      }
    }

    .wrapper-listing-and-container {
      display: flex;

      .listing-dan-transaksi {
        width: 190px;
        height: 66px;
        &:first-child {
          margin-right: 40px;
        }
        .amount-and-label {
          margin-bottom: 6px;
          p {
            color: #232323;

            /* headline */
            font-family: "SF Pro Display SemiBold";
            font-size: 17px;
            font-style: normal;
            line-height: 22px; /* 129.412% */
            letter-spacing: -0.43px;

            span {
              padding-bottom: 3px;
              border-bottom: 2px solid #232323;
            }
          }
        }
        .description {
          color: #7b7b7b;

          /* caption-1 */
          font-family: "SF Pro Display Regular";
          font-size: 12px;
          font-style: normal;
          line-height: 16px; /* 133.333% */
        }
      }
    }
  }

  .right {
    width: 685.346px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    img {
      width: 685.346px;
      height: 985px;
      object-fit: contain;
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: 0;
    }

    .scroll-to-listing {
      position: absolute;
      right: 50px;
      bottom: 39px;
      z-index: 2;
      border: none;
      display: flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border-radius: 2px;
      border: 0.5px solid #232323;
      background: #fff;
      color: #232323;
      /* subhead */
      font-family: "SF Pro Display Medium";
      font-size: 15px;
      font-style: normal;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.23px;
      cursor: pointer;
      .arrow-down {
        width: 16px;
        position: static;
        height: 16px;
        margin-right: 8px;
      }
    }
  }
}

@media (max-width: 1500px) {
  .wrapper {
    width: 100vw;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .profile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 960px;

    .left {
      width: 482px;
      padding-bottom: 80px;
      padding-top: 278px;
      .name {
        color: #232323;
        font-family: "SF Pro Display Medium";
        font-size: 60px;
        font-style: normal;
        line-height: 70px; /* 116.667% */
        letter-spacing: 0.26px;
        margin-bottom: 8px;
      }

      .level-and-company {
        margin-bottom: 24px;
        .level {
          color: #232323;
          font-family: "SF Pro Display SemiBold";
          font-size: 17px;
          font-style: normal;
          line-height: 22px; /* 129.412% */
          letter-spacing: -0.43px;
        }
        svg {
          width: 8px;
          height: 8px;
          margin: 0 16px;
        }
        .company {
          color: #232323;
          font-family: "SF Pro Display Regular";
          font-size: 17px;
          font-style: normal;
          line-height: 22px; /* 129.412% */
        }
      }

      .wrapper-hubungi-saya {
        width: 100%;
        display: flex;
        align-items: center;
        border-top: 1px solid #e9e9e9;
        padding-top: 16px;
        margin-bottom: 96px;

        button {
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 40px;
          box-sizing: border-box;
          padding: 12px 24px;
          width: 160px;
          height: 46px;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          border-radius: 2px;
          background: #2356a6;
          cursor: pointer;
          /* subhead */
          font-family: "SF Pro Display Medium";
          color: #fff;
          font-size: 15px;
          font-style: normal;
          line-height: 20px; /* 133.333% */
          letter-spacing: -0.23px;
        }

        .info-hub-wa {
          display: flex;

          svg {
            width: 16px;
            height: 16px;
            flex-shrink: 0;
            margin-right: 8px;
          }

          p {
            color: #7b7b7b;
            /* caption-1 */
            font-family: "SF Pro Display Regular";
            font-size: 12px;
            font-style: normal;
            line-height: 16px; /* 133.333% */
          }
        }
      }

      .wrapper-listing-and-container {
        display: flex;

        .listing-dan-transaksi {
          width: 160px;
          height: 66px;
          &:first-child {
            margin-right: 40px;
          }
          .amount-and-label {
            margin-bottom: 6px;
            p {
              color: #232323;

              /* headline */
              font-family: "SF Pro Display SemiBold";
              font-size: 17px;
              font-style: normal;
              line-height: 22px; /* 129.412% */
              letter-spacing: -0.43px;

              span {
                padding-bottom: 3px;
                border-bottom: 2px solid #232323;
              }
            }
          }
          .description {
            color: #7b7b7b;

            /* caption-1 */
            font-family: "SF Pro Display Regular";
            font-size: 12px;
            font-style: normal;
            line-height: 16px; /* 133.333% */
          }
        }
      }
    }

    .right {
      position: relative;
      width: 482px;
      img {
        left: 0;
        width: 509px;
        height: 802px;
        object-fit: contain;
        position: absolute;
        bottom: 0;
      }
    }
  }
}

.mobile-wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-top: 112px;

  .content {
    width: 100%;
    max-width: 378px;
    position: relative;
    .active-marketer-wrapper {
      display: inline-block;
      margin-bottom: 8px;
      .active-marketer {
        display: flex;
        align-items: center;
        font-family: "SF Pro Display SemiBold";
        font-size: 15px;
        padding: 8px;
        border-radius: 2px;
        background: #f2ecdd;
        box-shadow:
          0px 1px 2px 0px rgba(35, 35, 35, 0.08) inset,
          -1px -1px 2px 0px rgba(255, 227, 155, 0.25) inset;

        svg {
          margin-right: 4px;
        }
      }
    }
    .name {
      color: #232323;
      font-family: "SF Pro Display Medium";
      font-size: 34px;
      font-style: normal;
      line-height: 41px; /* 120.588% */
      letter-spacing: -0.45px;
      margin-bottom: 8px;
    }

    .level {
      color: #232323;
      font-family: "SF Pro Display SemiBold";
      font-size: 16px;
      font-style: normal;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.31px;
      padding-bottom: 4px;
      margin-bottom: 4px;
      padding-bottom: 1px solid #e9e9e9;
    }

    .company {
      font-size: 16px;
      font-style: normal;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.31px;
    }

    .layer-profile {
      width: 100%;
      height: 582.569px;
      position: relative;
      display: flex;
      justify-content: center;

      .profile-pic {
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 1;
      }

      button {
        border: none;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 2px;
        background: #2356a6;
        /* subhead */
        font-family: "SF Pro Display Medium";
        font-size: 15px;
        font-style: normal;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.23px;
        position: absolute;
        z-index: 2;
        bottom: 56px;
        width: 100%;
        cursor: pointer;
      }

      .info-hub-wa {
        position: absolute;
        z-index: 2;
        bottom: 24px;
        width: 100%;
        background: transparent;
        display: flex;
        align-items: center;
        svg {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        p {
          color: #fff;
          font-family: "SF Pro Display Regular";
          font-size: 10px;
          font-style: normal;
          line-height: 16px; /* 133.333% */
        }
      }

      .listing-dan-transaksi-mobile {
        position: absolute;
        padding: 8px 10px;
        border-radius: 2px;
        background: rgba(35, 35, 35, 0.5);
        width: 160px;
        z-index: 2;
        .amount-and-label {
          p {
            color: #fff;
            /* headline */
            font-family: "SF Pro Display SemiBold";
            font-size: 17px;
            font-style: normal;
            line-height: 22px; /* 129.412% */
            letter-spacing: -0.43px;
            margin-bottom: 3px;
            span {
              padding-bottom: 3px;
              border-bottom: 2px solid #fff;
            }
          }
        }
        .description {
          color: #fff;
          /* caption-1 */
          font-family: "SF Pro Display Regular";
          font-size: 12px;
          font-style: normal;
          line-height: 16px; /* 133.333% */
        }
        &.berhasil {
          right: 0;
          bottom: 250px;
        }

        &.listing {
          left: 0;
          bottom: 152px;
        }
      }
    }
  }
  .background {
    position: absolute;
    // width: 638px;
    width: 110vw;
    height: 297px;
    left: -79px;
    bottom: -54px;
    opacity: 0.4000000059604645;
    background: #000;
    filter: blur(40px);
  }
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background-color: rgba(0,0,0,.5);
  .modal-content {
    width: 422px;
    padding: 24px 16px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .active-marketer-wrapper {
      display: inline-block;
      margin-bottom: 16px;
      .active-marketer {
        display: flex;
        align-items: center;
        font-family: "SF Pro Display SemiBold";
        font-size: 15px;
        padding: 6px;
        border-radius: 2px;
        background: #f2ecdd;
        box-shadow:
          0px 1px 2px 0px rgba(35, 35, 35, 0.08) inset,
          -1px -1px 2px 0px rgba(255, 227, 155, 0.25) inset;

        svg {
          margin-right: 4px;
        }
      }
    }

    .description {
      color: #4f4f4f;
      text-align: center;
      font-size: 20px;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #ececec;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 12px 24px;
      border-radius: 2px;
      background: #2356a6;
      cursor: pointer;
      color: #fff;
      font-family: "SF Pro Display Medium";
      font-size: 15px;
      font-weight: 500;
    }
  }
}

@media (max-width: 478px) {
  .mobile-wrapper {
    .content {
      width: calc(100vw - 48px);
    }
  }

  .modal-wrapper{
    .modal-content{
      max-width: 302px;
    }
  }
}

@media (max-width: 430px) {
  .mobile-wrapper {
    margin-top: 112px;
  }
}

@media (max-width: 374px) {
  .mobile-wrapper {
    .content {
      width: 100vw;
      padding: 0 4vw;
    }
  }
}
