.wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  padding-bottom: 96px;
  background: #ffffff;

  .content {
    max-width: 1320px;
    width: 100%;
  }
}

.intro {
  color: #a7a7a7;
  margin-bottom: 96px;
  display: flex;
  align-items: center;
  span {
    /* title-2 */
    font-family: "SF Pro Display SemiBold";
    font-size: 28px;
    font-style: normal;
    line-height: 34px; /* 121.429% */
    letter-spacing: -0.33px;
    white-space: nowrap;
    margin-right: 24px;
  }

  .line {
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
  }
}

.wrapper-contacts {
  display: flex;

  .left {
    margin-right: 40px;
    width: 413px;
    .title {
      color: #a7a7a7;
      font-family: "SF Pro Display Regular";
      font-size: 28px;
      font-style: normal;
      line-height: 34px; /* 121.429% */
      letter-spacing: -0.33px;
    }
    .c21 {
      color: #232323;

      /* title-1 */
      font-family: "SF Pro Display SemiBold";
      font-size: 28px;
      font-style: normal;
      line-height: 34px; /* 121.429% */
      letter-spacing: -0.33px;
      margin-bottom: 24px;
    }

    .btn-to-c21 {
      border: none;
      border-radius: 2px;
      background: #2356a6;
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      color: #fff;
      height: 46px;
      /* subhead */
      font-family: "SF Pro Display Medium";
      font-size: 15px;
      font-style: normal;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.23px;
    }
  }

  .middle {
    margin-right: 40px;
    width: 413px;

    .middle-label {
      color: #a7a7a7;

      /* body */
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */
      margin-bottom: 8px;
    }
    .sosmeds {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      a {
        margin-right: 24px;

        .sosmed {
          width: 32px;
          height: 32px;
          flex-shrink: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .phone,
    .fax {
      color: #232323;

      /* body */
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */
      margin-bottom: 40px;
    }

    .fax {
      margin-bottom: 0;
    }
  }

  .right {
    width: 413px;

    .right-label {
      color: #a7a7a7;

      /* body */
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */
      margin-bottom: 8px;
    }
    .phone {
      color: #232323;

      /* body */
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */
      margin-bottom: 40px;
    }
    .address {
      color: #232323;
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */
      text-decoration-line: underline;
    }
  }
}

@media (max-width: 1500px) {
  .wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-bottom: 96px;
    .content {
      max-width: 960px;
      width: 100%;
    }
  }

  .intro {
    color: #a7a7a7;
    margin-bottom: 96px;
    display: flex;
    align-items: center;
    span {
      /* title-2 */
      font-family: "SF Pro Display SemiBold";
      font-size: 22px;
      font-style: normal;
      line-height: 28px; /* 127.273% */
      letter-spacing: -0.23px;
      white-space: nowrap;
    }

    .line {
      width: 100%;
      border-bottom: 1px solid #e9e9e9;
    }
  }

  .wrapper-contacts {
    display: flex;

    .left {
      margin-right: 40px;
      width: 360px;
      .title {
        color: #a7a7a7;
        font-family: "SF Pro Display Regular";
        font-size: 28px;
        font-style: normal;
        line-height: 34px; /* 121.429% */
        letter-spacing: -0.33px;
      }
      .c21 {
        color: #232323;

        /* title-1 */
        font-family: "SF Pro Display SemiBold";
        font-size: 28px;
        font-style: normal;
        line-height: 34px; /* 121.429% */
        letter-spacing: -0.33px;
        margin-bottom: 24px;
      }

      .btn-to-c21 {
        border: none;
        border-radius: 2px;
        background: #2356a6;
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        color: #fff;

        /* subhead */
        font-family: "SF Pro Display Medium";
        font-size: 15px;
        font-style: normal;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.23px;
      }
    }

    .middle {
      margin-right: 40px;
      width: 260px;
      .middle-label {
        color: #a7a7a7;

        /* body */
        font-family: "SF Pro Display Regular";
        font-size: 17px;
        font-style: normal;
        line-height: 22px; /* 129.412% */
        margin-bottom: 8px;
      }
      .sosmeds {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        a {
          margin-right: 24px;

          .sosmed {
            width: 32px;
            height: 32px;
            flex-shrink: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .phone,
      .fax {
        color: #232323;

        /* body */
        font-family: "SF Pro Display Regular";
        font-size: 17px;
        font-style: normal;
        line-height: 22px; /* 129.412% */
        margin-bottom: 40px;
      }

      .fax {
        margin-bottom: 0;
      }
    }

    .right {
      width: 260px;

      .right-label {
        color: #a7a7a7;

        /* body */
        font-family: "SF Pro Display Regular";
        font-size: 17px;
        font-style: normal;
        line-height: 22px; /* 129.412% */
        margin-bottom: 8px;
      }
      .phone {
        color: #232323;

        /* body */
        font-family: "SF Pro Display Regular";
        font-size: 17px;
        font-style: normal;
        line-height: 22px; /* 129.412% */
        margin-bottom: 40px;
      }
      .address {
        color: #232323;
        font-family: "SF Pro Display Regular";
        font-size: 17px;
        font-style: normal;
        line-height: 22px; /* 129.412% */
        text-decoration-line: underline;
      }
    }
  }
}

@media (max-width: 1023px) {
  .wrapper {
    padding: 80px 0;
    .content {
      max-width: 378px;
    }
  }

  .intro {
    margin-bottom: 80px;
    span {
      color: #a7a7a7;

      /* headline */
      font-family: "SF Pro Display SemiBold";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */
      letter-spacing: -0.43px;
      white-space: nowrap;
      margin-right: 24px;
    }
    .line {
      width: 100%;
    }
  }

  .wrapper-contacts {
    display: block;
    .left {
      width: 100%;
      margin-bottom: 40px;
      margin-right: 0;
      .title {
        color: #a7a7a7;

        /* title-3 */
        font-family: "SF Pro Display Regular";
        font-size: 20px;
        font-style: normal;
        line-height: 25px; /* 125% */
        letter-spacing: -0.45px;
      }
      .c21 {
        color: #232323;

        /* title-2 */
        font-family: "SF Pro Display SemiBold";
        font-size: 22px;
        font-style: normal;
        line-height: 28px; /* 127.273% */
        letter-spacing: -0.23px;
      }
      .btn-to-c21 {
        /* subhead */
        font-family: "SF Pro Display Medium";
        font-size: 15px;
        font-style: normal;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.23px;
        margin-right: 0;
      }
    }

    .middle {
      width: 100%;
      margin-right: 0;
      margin-bottom: 40px;

      .middle-label {
        color: #a7a7a7;

        /* footnote */
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;
      }

      .phone,
      .fax {
        color: #232323;

        /* callout */
        font-family: "SF Pro Display Regular";
        font-size: 16px;
        font-style: normal;
        line-height: 21px; /* 131.25% */
        letter-spacing: -0.31px;
      }
    }

    .right {
      width: 100%;
      .right-label {
        color: #a7a7a7;

        /* footnote */
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;
      }
      .phone {
        color: #232323;

        /* callout */
        font-family: "SF Pro Display Regular";
        font-size: 16px;
        font-style: normal;
        line-height: 21px; /* 131.25% */
        letter-spacing: -0.31px;
      }
      .address {
        color: #232323;

        /* callout */
        font-family: "SF Pro Display Regular";
        font-size: 16px;
        font-style: normal;
        line-height: 21px; /* 131.25% */
        letter-spacing: -0.31px;
      }
    }
  }
}

@media (max-width: 425px) {
  .wrapper {
    padding: 80px 24px;

    .content {
      max-width: unset;
      width: 100%;
    }
  }
}

@media (max-width: 374px) {
  .wrapper {
    padding: 80px 4vw;
  }

  .wrapper-contacts{
    .left{
      .btn-to-c21 {
        margin-right: 0;
      }
    }
  }
}
