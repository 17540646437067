.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.title {
  color: #575757;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 11px;
  font-style: normal;
  line-height: 13px; 
  letter-spacing: -0.4px;
  margin-bottom: 16px;
}
