.wrapper-outer{
  position: relative;

  
  .emblem {
    position: absolute;
    right: 0;
    top: 0;
    width: 1150px;
    height: 366px;
  }
}
.wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
  position: fixed;
  box-sizing: border-box;
  padding-top: 24px;
  .content {
    max-width: 1320px;
    width: 100%;
    display: flex;
    height: 64px;
    align-items: center;
    .logo-c21 {
      width: 37.647px;
      height: 48px;
      margin-right: 80px;
    }

    .menus {
      display: flex;
      align-items: center;
      .menu {
        color: #232323;
        cursor: pointer;
        /* caption-link-1 */
        font-family: "SF Pro Display Medium";
        /* subhead */
        font-size: 15px;
        font-style: normal;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.23px;
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    button {
      display: none;
    }
  }
}

.scrolled {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  backdrop-filter: blur(20px);
  z-index: 5;
}

@media (max-width: 1500px) {
  .wrapper-outer{
    .emblem {
      width: 791px;
      height: 366px;
    }
  }
  .wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    .content {
      max-width: 960px;
      width: 100%;
      display: flex;
      .logo-c21 {
        width: 25.098px;
        height: 32px;
        flex-shrink: 0;
        margin-right: 80px;
      }

      .menus {
        display: flex;
        align-items: center;
        .menu {
          color: #232323;
          cursor: pointer;
          /* caption-link-1 */
          font-family: "SF Pro Display SemiBold";
          font-size: 12px;
          font-style: normal;
          line-height: 16px; /* 133.333% */
          margin-right: 40px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      button {
        display: none;
      }
    }

    .emblem {
      width: 791px;
      height: 366px;
    }
  }
}

@media (max-width: 1023px) {
  .wrapper-outer{
    .emblem {
      position: absolute;
      right: 0;
      top: 0;
      width: 163px;
      height: 338px;
    }
  }
  .wrapper {
    padding-top: 48px;
    position: absolute;
    .content {
      max-width: 378px;
      justify-content: space-between;
      align-items: center;
      height: 51px;

      .logo-c21 {
        width: 37.647px;
        height: 48px;
        object-fit: contain;
      }

      .menus {
        display: none;
      }

      button {
        border: none;
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        border: 0.5px solid #232323;
        background: #fff;
        box-sizing: border-box;
        display: none;
        .arrow-down {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          object-fit: contain;
        }

        color: #232323;

        /* caption-1 */
        font-family: "SF Pro Display Regular";
        font-size: 12px;
        font-style: normal;
        line-height: 16px; /* 133.333% */
      }
    }

    .emblem {
      position: absolute;
      right: 0;
      top: 0;
      width: 163px;
      height: 338px;
    }
  }

  .scrolled {
    margin-top: 0;
  }
}

@media (max-width: 478px) {
  .wrapper {
    .content {
      width: calc(100vw - 48px);
    }
  }
}

@media (max-width: 430px) {
  .wrapper {
    .content {
      max-width: 378px;
      justify-content: space-between;
      .menus {
        display: none;
      }
      .logo-c21 {
        width: 37.647px;
        height: 48px;
        flex-shrink: 0;
        margin-right: 60px;
      }

      button {
        border: none;
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        border: 0.5px solid #232323;
        background: #fff;

        .arrow-down {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }

        color: #232323;

        /* caption-1 */
        font-family: "SF Pro Display Regular";
        font-size: 12px;
        font-style: normal;
        line-height: 16px; /* 133.333% */
      }
    }
  }
}

@media (max-width: 374px) {
  .wrapper {
    .content {
      width: 92vw;
      justify-content: space-between;
      .logo-c21 {
        margin-right: 0;
      }
      button {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 330px) {
  .wrapper {
    .content {
      button {
        margin-right: 0;
        display: none;
      }
    }
  }
}
