.wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 200px 0;
  position: relative;
  .content {
    max-width: 1320px;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.bg-c21 {
  right: 0;
  top: -50%;
  position: absolute;
}

.left {
  width: 360px;
  height: 360px;
  margin-right: 104px;
}

.right {
  width: calc(100% - 360px - 104px);
  .title {
    color: #a7a7a7;

    /* ax-2 */
    font-family: "SF Pro Display Regular";
    font-size: 48px;
    font-style: normal;
    line-height: 57px; /* 118.75% */
    letter-spacing: 0.35px;
  }

  .name {
    color: #232323;
    font-family: "SF Pro Display Medium";
    font-size: 48px;
    font-style: normal;
    line-height: 57px; /* 118.75% */
    letter-spacing: 0.35px;
    margin-bottom: 4px;
  }

  .company {
    color: #7b7b7b;

    /* footnote */
    font-family: "SF Pro Display Regular";
    font-size: 17px;
    font-style: normal;
    line-height: 22px; /* 129.412% */
    margin-bottom: 48px;

    span {
      color: #232323;

      /* subhead */
      font-family: "SF Pro Display SemiBold";
      font-size: 22px;
      font-style: normal;
      line-height: 28px; /* 127.273% */
      letter-spacing: -0.23px;
    }
  }

  .lokasi-kantor {
    color: #a7a7a7;

    /* body */
    font-family: "SF Pro Display Regular";
    font-size: 17px;
    font-style: normal;
    line-height: 22px; /* 129.412% */
    margin-bottom: 4px;
  }

  .location {
    display: flex;

    color: #232323;
    font-family: "SF Pro Display Regular";
    font-size: 22px;
    font-style: normal;
    line-height: 28px; /* 127.273% */
    letter-spacing: -0.23px;
    text-decoration-line: underline;
    margin-bottom: 48px;
    svg {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
    }
  }
  .phone-label {
    color: #a7a7a7;

    /* body */
    font-family: "SF Pro Display Regular";
    font-size: 17px;
    font-style: normal;
    line-height: 22px; /* 129.412% */
  }
  .phone {
    color: #232323;

    /* body */
    font-family: "SF Pro Display Regular";
    font-size: 22px;
    font-style: normal;
    line-height: 28px; /* 127.273% */
    letter-spacing: -0.23px;
  }
}

@media (max-width: 1500px) {
  .wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 200px 0;
    .content {
      max-width: 960px;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .left {
    width: 360px;
    height: 360px;
    margin-right: 40px;
  }

  .right {
    width: calc(100% - 360px - 40px);
    .title {
      color: #a7a7a7;

      /* ax-2 */
      font-family: "SF Pro Display Regular";
      font-size: 48px;
      font-style: normal;
      line-height: 57px; /* 118.75% */
      letter-spacing: 0.35px;
    }

    .name {
      color: #232323;
      font-family: "SF Pro Display Medium";
      font-size: 48px;
      font-style: normal;
      line-height: 57px; /* 118.75% */
      letter-spacing: 0.35px;
      margin-bottom: 4px;
    }

    .company {
      color: #7b7b7b;

      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;
      margin-bottom: 48px;

      span {
        color: #232323;

        /* subhead */
        font-family: "SF Pro Display Medium";
        font-size: 15px;
        font-style: normal;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.23px;
      }
    }

    .lokasi-kantor {
      color: #a7a7a7;

      /* body */
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */
      margin-bottom: 4px;
    }

    .location {
      display: flex;

      color: #232323;
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */
      text-decoration-line: underline;
      margin-bottom: 48px;
      svg {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
      }
    }
    .phone-label {
      color: #a7a7a7;

      /* body */
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */
    }
    .phone {
      color: #232323;

      /* body */
      font-family: "SF Pro Display Regular";
      font-size: 17px;
      font-style: normal;
      line-height: 22px; /* 129.412% */
    }
  }
}

@media (max-width: 1023px) {
  .wrapper {
    padding: 80px 0;
    .content {
      max-width: 378px;
      display: block;
    }
  }

  .left {
    height: 327px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }

  .right {
    width: 100%;
    .title {
      color: #a7a7a7;

      /* title-3 */
      font-family: "SF Pro Display Regular";
      font-size: 20px;
      font-style: normal;
      line-height: 25px; /* 125% */
      letter-spacing: -0.45px;
    }

    .name {
      color: #232323;
      font-family: "SF Pro Display Medium";
      font-size: 34px;
      font-style: normal;
      line-height: 41px; /* 120.588% */
      letter-spacing: -0.45px;
      margin-bottom: 2px;
    }

    .company {
      color: #7b7b7b;

      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;
      margin-bottom: 48px;
      span {
        color: #232323;

        /* subhead */
        font-family: "SF Pro Display Medium";
        font-size: 15px;
        font-style: normal;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.23px;
        display: block;
      }
    }

    .lokasi-kantor {
      color: #a7a7a7;

      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;
      margin-bottom: 8px;
    }

    .location {
      color: #232323;
      font-family: "SF Pro Display Regular";
      font-size: 16px;
      font-style: normal;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.31px;
      text-decoration-line: underline;
      svg {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        margin-top: 3px;
      }
    }
    .phone-label {
      color: #a7a7a7;

      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.08px;
      margin-bottom: 8px;
    }
    .phone {
      color: #232323;

      /* callout */
      font-family: "SF Pro Display Regular";
      font-size: 16px;
      font-style: normal;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.31px;
    }
  }
}

@media (max-width: 425px) {
  .wrapper {
    padding: 80px 24px;

    .content {
      max-width: unset;
      width: 100%;
    }
  }
  .bg-c21 {
    width: 249px;
    height: 317.475px;
  }
}


@media (max-width: 374px) {
  .wrapper {
    padding: 80px 4vw;
  }
}