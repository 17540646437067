.wrapper {
  padding: 200px 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
  background: #fcfcfc;
  .content {
    max-width: 1516px;
  }
  .wrapper-banner {
    width: 760px;
    height: 190px;
    position: absolute;

    z-index: 1;
    top: 80px;
    .banner {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.wrapper-achievement {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.02);
  width: 100%;
  padding: 104px 98px 80px 98px;
}

.wrapper-top-five {
  .t5-achievement {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #e9e9e9;

    &:last-child {
      margin-bottom: 40;
    }
  }
  .wrapper-title-info {
    border-right: 1px solid #e9e9e9;
    width: 1015px;
    .title {
      color: #232323;
      /* title-1 */
      font-size: 40px;
      font-style: normal;
      font-family: "SF Pro Display SemiBold";
      line-height: 48px; /* 120% */
      letter-spacing: 0.37px;
    }
    .info {
      color: #7b7b7b;
      /* footnote */
      font-size: 16px;
      font-style: normal;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.31px;
      font-family: "SF Pro Display Regular";
      border-right: 1px solid #e9e9e9;
    }
  }
  .wrapper-year-years {
    .year {
      color: #232323;
      /* title-1 */
      font-size: 40px;
      font-style: normal;
      font-family: "SF Pro Display SemiBold";
      line-height: 48px; /* 120% */
      letter-spacing: 0.37px;
      text-align: right;
    }
    .years {
      color: #7b7b7b;
      /* footnote */
      font-family: "SF Pro Display Regular";
      font-size: 16px;
      font-style: normal;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.31px;
      text-align: right;
    }
  }
}

.penghargaan-lainnya {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  span {
    color: #a7a7a7;
    font-family: "SF Pro Display SemiBold";
    /* title-1 */
    font-size: 28px;
    font-style: normal;

    line-height: 34px; /* 121.429% */
    letter-spacing: -0.33px;
    white-space: nowrap;
  }

  .line {
    height: 1px;
    border-bottom: 1px solid #e9e9e9;
    width: 100%;
  }
}

.other-achievement {
  color: #232323;

  /* headline */
  font-family: "SF Pro Display SemiBold";
  font-size: 22px;
  font-style: normal;
  line-height: 28px; /* 127.273% */
  letter-spacing: -0.23px;
}

@media (max-width: 1500px) {
  .wrapper {
    padding: 200px 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    position: relative;

    .content {
      max-width: 960px;
    }
    .wrapper-banner {
      width: 760px;
      height: 190px;
      position: absolute;

      z-index: 1;
      top: 80px;
      .banner {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .wrapper-achievement {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.02);
    width: 100%;
    padding: 104px 98px 80px 98px;
  }

  .wrapper-top-five {
    .t5-achievement {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 40;
      }
    }
    .wrapper-title-info {
      width: 100%;
      .title {
        color: #232323;
        /* title-1 */
        font-family: "SF Pro Display SemiBold";
        font-size: 28px;
        font-style: normal;

        line-height: 34px; /* 121.429% */
        letter-spacing: -0.33px;
      }
      .info {
        color: #7b7b7b;
        /* footnote */
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;
      }
    }
    .wrapper-year-years {
      width: 280px;
      .year {
        color: #232323;
        /* title-1 */
        font-family: "SF Pro Display SemiBold";
        font-size: 28px;
        font-style: normal;

        line-height: 34px; /* 121.429% */
        letter-spacing: -0.33px;
      }
      .years {
        color: #7b7b7b;
        /* footnote */
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;
      }
    }
  }

  .penghargaan-lainnya {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    span {
      color: #a7a7a7;
      /* title-2 */
      font-family: "SF Pro Display SemiBold";
      font-size: 22px;
      font-style: normal;

      line-height: 28px; /* 127.273% */
      letter-spacing: -0.23px;
      margin-right: 24px;
      white-space: nowrap;
    }

    .line {
      height: 1px;
      border-bottom: 1px solid #e9e9e9;
      width: 100%;
    }
  }

  .other-achievement {
    color: #232323;

    /* headline */
    font-family: "SF Pro Display SemiBold";
    font-size: 17px;
    font-style: normal;

    line-height: 22px; /* 129.412% */
    letter-spacing: -0.43px;
  }
}

@media (max-width: 1023px) {
  .wrapper {
    padding: 80px 0;
    .content {
      max-width: 378px;
    }
    .wrapper-banner {
      width: 279px;
      height: 190px;
      top: -30px;
    }
  }

  .wrapper-achievement {
    padding: 104px 24px 24px 24px;
  }

  .wrapper-top-five {
    .t5-achievement {
      display: block;
      padding-bottom: 40px;
      border-bottom: 1px solid #e9e9e9;
      &:last-child {
        border-bottom: 0;
      }
    }
    .wrapper-title-info {
      margin-bottom: 20px;
      width: 100%;
      border-right: unset;
      .title {
        /* headline */
        font-family: "SF Pro Display SemiBold";
        font-size: 17px;
        font-style: normal;

        line-height: 22px; /* 129.412% */
        letter-spacing: -0.43px;
      }
      .info {
        /* footnote */
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;
      }
    }
    .wrapper-year-years {
      .year {
        /* headline */
        font-family: "SF Pro Display SemiBold";
        font-size: 17px;
        font-style: normal;

        line-height: 22px; /* 129.412% */
        letter-spacing: -0.43px;
        text-align: left;
      }
      .years {
        /* footnote */
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        font-style: normal;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.08px;
        text-align: left;
      }
    }
  }

  .penghargaan-lainnya {
    padding-bottom: 16px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 24px;
    span {
      color: #a7a7a7;

      /* headline */
      font-family: "SF Pro Display SemiBold";
      font-size: 17px;
      font-style: normal;

      line-height: 22px; /* 129.412% */
      letter-spacing: -0.43px;
    }
  }

  .other-achievement {
    color: #232323;

    /* subhead */
    font-family: "SF Pro Display Medium";
    font-size: 15px;
    font-style: normal;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.23px;
  }
}

@media (max-width: 425px) {
  .wrapper {
    padding: 80px 24px;

    .content {
      max-width: unset;
      width: 100%;
    }
  }
}

@media (max-width: 374px) {
  .wrapper{
    padding: 80px 4vw;
  
    .wrapper-banner{
      width: calc(92vw);
    }
  }
  .wrapper-achievement{
    padding: 104px 4vw 24px 4vw;
  }
  .penghargaan-lainnya{
    span{
      font-size: 4vw;
      display: block;
    }
  }
}
